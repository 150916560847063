import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

type AppSearchProps = {
  onSearch: (value: string) => void
}

export const AppSearch = ({ onSearch }: AppSearchProps) => {
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("")

    const onChange = (e: React.KeyboardEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.defaultValue)

    return (
      <Search
        placeholder={t("search.placeholder")}
        enterButton={<Button data-testid="btnSearch" type="primary" disabled={searchTerm.length < 3}>Search</Button>}
        size="large"
        onKeyUp={onChange}
        onSearch={onSearch}
        loading={false}
        />
    )
}