import { Avatar, Card, List, Tag, Tooltip } from "antd"
import React from "react"
import { MovieOverview } from "../typings"

const styles = require("./ListItem.module.scss")
const { Meta } = Card

export type ListItemProps = {
  index: string
  movie: MovieOverview
  onClick: (id: string) => void
  style: React.CSSProperties
}

export const ListItem = ({ movie, onClick, style, index }: ListItemProps) => {
  const { Title, Type, Year, Poster, imdbID } = movie

  return (
    <List.Item style={style} key={"element_"+index} className={styles.movieItem} onClick={() => onClick(imdbID)}>
      <Card loading={false}>
        <Meta
          avatar={
            <Avatar className={styles.moviePoster} shape="square" src={Poster !== 'N/A' ? Poster : "assets/notfound.png"} />
          }
          title={
            <Tooltip title={Title}>
              <span>{Title}</span>
            </Tooltip>
          }
          description={(
            <div>
              <span className={styles.mr6}>Year:</span> <Tag color="lime">{Year}</Tag>
              <span className={styles.mr6}>Category:</span><Tag color="lime">{Type}</Tag>
            </div>
          )}
        />
      </Card>
    </List.Item>
  )
}