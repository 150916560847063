import { useEffect, useRef, useState } from 'react';
import { BackTop } from 'antd';
import { AppSearch } from './components/Search';

import 'antd/dist/antd.css';
import List from './components/List';
import { MovieOverview } from './typings';
import { manyResultsErr, notFoundErr } from './components/constants';

const styles = require('./App.module.scss');

const fetchData = async (page: number, searchTerm: string) => {
  const result = await fetch("https://www.omdbapi.com/?apikey=b9bd48a6&page="+page+"&type=movie&s="+searchTerm)
    .then(res => res.json())
  
  const total = parseInt(result.totalResults)
  
  let error = ""
  switch(result.Error) {
    case "Movie not found!":
        error = notFoundErr
        break
    case "Too many results.":
        error = manyResultsErr
        break
  }

  return {movies: result.Search, error, total: isNaN(total) ? 0 : total }
}

function App() {
  const [searchTerm, setSearchTerm] = useState("")
  const [movies, setMovies] = useState([] as MovieOverview[])
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const ref = useRef(false)

  const isPageFetched = (inputPage: number) => movies[(inputPage * 10) - 1] !== undefined
  
  const isGreaterThanLastPage = (inputPage: number) => total > 0 && inputPage > Math.ceil(total / 10)

  const loadData = async (value?: string) => {
    const toSearch = value && value !== "" ? value : searchTerm
    const pageToSearch = page + 1

    if (isPageFetched(pageToSearch) || isGreaterThanLastPage(pageToSearch)) {
      return
    }

    setLoading(true)

    const result = await fetchData(pageToSearch, toSearch);
    setError(result.error)
    setPage(pageToSearch)

    if (result.movies && result.movies.length > 0) {
      const resMovies = movies.concat(result.movies)
      setMovies(resMovies)
    }

    if (result.total > 0) {
      setTotal(result.total)
    }

    setLoading(false)
  }

  const initState = (value: string) => {
    setMovies([])
    setError("")
    setPage(0)
    setTotal(0)
    setLoading(false)
    setSearchTerm(value)
    ref.current = true
  }

  const onSearch = async (value: string) => {
    initState(value)
  };

  useEffect(() => {
    if (ref.current) {
      loadData()
      ref.current = false
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current])

  return (
    <div className={styles.App}>
      <BackTop />

      <div className={styles.header}>
        <AppSearch onSearch={onSearch} />
      </div>

      <div className={styles.results}>
        <List searchTerm={searchTerm} movies={movies} total={total} loading={loading} error={error} loadData={loadData}  />
      </div>
    </div>
  );
}

export default App;
