import 'antd/dist/antd.css';
import { Spin, Divider } from 'antd';
import { MovieOverview } from '../typings';
import { EmptyManyResults, EmptyNoData, EmptyNotFound, EmptyNothingSearched } from './Empty';
import { InfiniteList } from './InfiniteList';
import { manyResultsErr, notFoundErr } from './constants';

const styles = require("./List.module.scss")

type ListProps = {
  searchTerm: string
  movies: MovieOverview[]
  error: string
  total: number
  loading: boolean
  loadData: () => Promise<void>
}

const Loader = () => <Spin className={styles.demoLoading} />

const ListComponent = ({ searchTerm, movies, error, total, loading, loadData }: ListProps) => {
    if (loading && movies.length === 0) {
      return <Loader />
    }

    if (searchTerm === "") {
      return <EmptyNothingSearched />;
    }

    switch (error) {
      case notFoundErr:
        return <EmptyNotFound />;
      case manyResultsErr:
        return <EmptyManyResults />
    }

    if (total === 0 || !movies || movies.length === 0) {
      return <EmptyNoData />
    }

    return (
      <>
        <Divider orientation="left">{total} results for "{searchTerm}"</Divider>
        {<InfiniteList movies={movies} total={total} load={loadData} />}
        {loading && <Loader />}
      </>
    )
}

export default ListComponent;