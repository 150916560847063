import { Breadcrumb, Spin, Image, Row, Col, Statistic, Tag } from "antd"
import cx from 'classnames';
import React, { useEffect, useState } from "react"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import { MovieDetail } from "../typings"
import { LikeOutlined } from '@ant-design/icons';
import { movieDetails } from './movieConfig'

import 'antd/dist/antd.css';
const styles = require("./Details.module.scss")

export const Details = () => {
  let match = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route path={`${match.path}/:id`}>
          <DetailsComponent />
        </Route>
      </Switch>
    </div>
  )
}

const fetchMovie = async (id: string, callback: Function) => {
  return await fetch("https://www.omdbapi.com/?apikey=b9bd48a6&type=movie&i="+id)
    .then(res => res.json())
    .then(s => {
      if (s == null) {
        callback(null)
      }
      callback(s)
    })
}

export const DetailsComponent = (): JSX.Element => {
  const { id } = useParams<{id: string}>();
  const [movie, setMovie] = useState<MovieDetail | null>(null)

  useEffect(() => {
    fetchMovie(id, setMovie)
  }, [id])

  if (!movie) {
    return <Spinner />
  }

  return (
    <div data-testid="movieDetails" className={cx(styles.Movie, styles.details)}>
      <Breadcrumb separator=">">
        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
        <Breadcrumb.Item>{movie.Title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className={styles.block}>
        <Row>
          <Col xs={24} md={8} className={styles.textCenter}>
            <Image
              data-testid="moviePoster"
              width={200}
              src={movie.Poster === "N/A" ? "../assets/notfound.png" : movie.Poster}
            />
          </Col>
          <Col xs={24} md={16}>
            <div className={styles.list}>
              {movieDetails.map((m, index) => (
                <div className={styles.row} key={index}>
                  <span className={styles.key}>{m.label}:</span>
                  {m.usePara ?
                    <p>{movie[m.key as keyof MovieDetail]}</p> :
                    <Tag color="magenta">{movie[m.key as keyof MovieDetail]}</Tag>
                  }
                </div>
              ))}
            </div>
          </Col>
        </Row>
        <Row  gutter={{ xs: 24, md: 8 }} className={cx(styles.textCenter, styles.mt4)}>
          <Col xs={12} md={8}>
            <Statistic title="Votes" value={movie.imdbVotes} prefix={<LikeOutlined />} />
          </Col>
          <Col xs={12} md={8}>
            <Statistic data-testid="movieRating" title="Rating" value={movie.imdbRating} suffix={movie.imdbRating === "N/A" ? "" : "/ 10"} />
          </Col>
        </Row>
      </div>
    </div>
  )
}

const Spinner = () => (
  <div className={styles.spinner} data-testid="loading">
    <Spin size="large" spinning={true} tip="Loading" />
  </div>
)