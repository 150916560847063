import React from "react";
import {
BrowserRouter as Router,
Switch,
Route
} from "react-router-dom";
import App from "./App";
import { Details } from "./components/Details";

export default function Main() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <App />
          </Route>
          <Route path="/details">
            <Details />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}