export const movieDetails = [
  {
    "key": "Plot",
    "label": "Plot",
    "usePara": true
  },
  {
    "key": "Actors",
    "label": "Cast" 
  },
  {
    "key": "Country",
    "label": "Country" 
  },
  {
    "key": "Director",
    "label": "Director" 
  },
  {
    "key": "Released",
    "label": "Released" 
  },
  {
    "key": "Language",
    "label": "Language" 
  },
  {
    "key": "Genre",
    "label": "Genre" 
  },
  {
    "key": "Runtime",
    "label": "Run time" 
  }
]