import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ListChildComponentProps, VariableSizeList as VList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { MovieOverview } from "../typings";
import { ListItem } from "./ListItem";

type InfiniteListProps = {
  movies: MovieOverview[]
  total: number
  load: () => Promise<void>
}

export const InfiniteList = ({ movies, total, load } : InfiniteListProps) => {
  const history = useHistory()

  const onClick = (id: string) => history.push("/details/"+id)

  const isItemLoaded = (index: number) => !!movies[index];

  let itemStatusMap: {[key: string]: boolean} = {};

  const Row = ({ index, style }: ListChildComponentProps) => (
    <ListItem key={"list-key__"+index} style={style} index={""+index} movie={movies[index]} onClick={onClick} />
  )

  const loadMore = async (startIndex: number, stopIndex: number) => {
    for (let index = startIndex; index <= stopIndex; index++) {
      itemStatusMap[index] = true;
    }
    
    await load()
    return Promise.resolve()
  }

  useEffect(() => {
    load()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={total}
      loadMoreItems={loadMore}
      threshold={7}
    >
      {({ onItemsRendered, ref }) => (
        <VList
          className="List"
          height={800}
          itemCount={movies.length}
          itemSize={() => 160}
          width={400}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {Row}
        </VList>
      )}
    </InfiniteLoader>
  );
}